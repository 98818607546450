<template>
  <div>
    <div class="logo">
      cloudpay
    </div>
    <div class="container">
      <div class="content">
        <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" class="icon-success">
          <!-- SVG path code here -->
          <path
              d="M24,4 C35.045695,4 44,12.954305 44,24 C44,35.045695 35.045695,44 24,44 C12.954305,44 4,35.045695 4,24 C4,12.954305 12.954305,4 24,4 Z M34.5548098,16.4485711 C33.9612228,15.8504763 32.9988282,15.8504763 32.4052412,16.4485711 L32.4052412,16.4485711 L21.413757,27.5805811 L21.413757,27.5805811 L21.4034642,27.590855 C21.0097542,27.9781674 20.3766105,27.9729811 19.9892981,27.5792711 L19.9892981,27.5792711 L15.5947588,23.1121428 C15.0011718,22.514048 14.0387772,22.514048 13.4451902,23.1121428 C12.8516033,23.7102376 12.8516033,24.6799409 13.4451902,25.2780357 L13.4451902,25.2780357 L19.6260786,31.5514289 C20.2196656,32.1495237 21.1820602,32.1495237 21.7756472,31.5514289 L21.7756472,31.5514289 L34.5548098,18.614464 C35.1483967,18.0163692 35.1483967,17.0466659 34.5548098,16.4485711 Z"></path>
        </svg>
        <p>Pay Success</p>
        <!--      <button class="button">return</button>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="css" scoped>

.container {
  padding: 0 0.5rem;
}

.logo {
  padding: 0.1rem;
  background-color: #FFFFFF;
  font-size: 0.5rem;
  font-weight: bold;
}

.content {
  width: 100%;
  padding: 20px;
  margin-top: 30px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(128, 128, 128, 0.2);
  border-radius: 0.3rem;
}

.content p {
  font-size: 32px;
}

.content svg {
  width: 1.5rem;
  height: 1.5rem;
}

.icon-success {
  fill: #67c23a;
}

.button {
  margin-top: 60px;
  display: inline-block;
  padding: 5px 20px;
  border: 1px solid #69bf4e;
  background-color: #FFFFFF;
  border-radius: 30px;
  color: #69bf4e;
  text-align: center;
  font-size: 16px;
}
</style>
